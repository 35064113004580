import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from 'components';

interface LoadingBarProps {
  duration: number;
  showPercents?: boolean;
  onComplete: () => void;
}

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.light0};
  position: relative;
  width: 257px;
  border-radius: 16px;
`;

const ProgressFill = styled.div<{ progress: number; duration: number }>`
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  position: absolute;
  border-radius: 16px;
  width: ${({ progress }) => progress}%;
  transition: width ${({ duration }) => duration}s linear;
`;

const StyledPercentsText = styled(Text)`
  padding-top: 1rem;
`;

const LoadingBar: FC<LoadingBarProps> = ({
  duration,
  showPercents,
  onComplete,
}) => {
  const [progress, setProgress] = useState(0);
  const [progressPercents, setProgressPercents] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 100 / duration;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 10);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, duration * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [duration, onComplete]);

  useEffect(() => {
    const onePercentTimeIteration = (duration * 1000) / 100;

    const intervalId = setInterval(() => {
      setProgressPercents(prevProgress =>
        prevProgress > 100 ? 100 : prevProgress + 1,
      );
    }, onePercentTimeIteration);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, duration * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [duration, onComplete]);

  return (
    <ProgressBar>
      <ProgressFill progress={progress} duration={duration} />
      {showPercents && (
        <StyledPercentsText textAlign="center" type="bodyM700" color="primary">
          {progressPercents}%
        </StyledPercentsText>
      )}
    </ProgressBar>
  );
};

export default LoadingBar;
